html {
  overflow-x: hidden;
}
/* create user */
.error-select {
  border: 1px solid #dc3545 !important;
}

.error-severity {
  border: 1px solid #dc3545 !important;
}

/* number increment and decrement icon removal */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-id {
  height: 700px;
  width: 663px !important;
}

.isDisabled {
  color: black;
  cursor: none !important;
  pointer-events: none;
  opacity: 0.5;
  text-decoration: none;
}

.element-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.negative-margin-1 {
  margin-top: -1rem !important;
}

.sticky-bottom {
  position: sticky !important;
  bottom: 0;
}

.bg-grey-lighten-2 {
  background-color: $grey-lighten-2;
}

.filters .select-input.form-control[readonly]:not([disabled]) {
  background-color: transparent !important;
}

.circle-initials {
  height: 50px;
  width: 100px;
  line-height: 50px;
  border-radius: 50%;
  background: #eee;
  border: 1px solid #fff;
  text-align: center;
}

.map-container {
  height: 100% !important;
}

.popconfirm-popover {
  min-width: 320px !important;
}

mdb-tabs > ul {
  border-bottom: 1px solid #ddd !important;
  li > a {
    padding: 0.5rem !important;
  }
}

// mdb-tabs > ul > li > a {
//   padding: 0.5rem !important;
// }
