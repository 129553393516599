.owl-theme .owl-nav [class*="owl-"] {
  color: $darker;
  font-size: 34px;
  margin: 5px;
  padding: 4px 7px;
  display: inline-block;
  cursor: pointer;
  line-height: 2.4rem;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  color: $darker !important;
  text-decoration: none;
}

.owl-nav {
  [class*="owl-"] {
    height: 3rem;
    width: 3rem;
    background: $lighter !important;
    border-radius: 50% !important;
    position: absolute;
    top: 50%;
  }
}

.owl-prev {
  left: -10px;
  top: 55px !important;
}
.owl-next {
  right: -10px;
  top: 55px !important;
}

.owl-carousel .owl-stage {
  display: flex;
}

.owl-carousel .owl-item img {
  width: auto;
  height: 100%;
}

.middle-slide {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

.first-slide {
  border-right: 1px solid #eee;
}
