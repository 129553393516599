/* custom skin */
$skins: () !default;
$skins: map-merge(
  (
    "HPS": (
      "skin-transparent-color": transparent,
      "skin-primary-color": #67d590,
      "skin-accent-color": #d567ac,
      "skin-analogous-color": #67acd5,
      "skin-primary-color-dark": #3c9584,
      "skin-secondary-color": #67acd5,
      "skin-gray-color": #999999,
      "skin-secondary-color-dark": darken(#67acd5, 10%),
      "skin-extended-color": #333,
      "skin-navbar": #fff,
      "skin-success-color": $success-color,
      "skin-footer-color": #fff,
      "skin-flat": $grey-lighten-2,
      "skin-sidenav": #f5f5f5,
      "skin-sidenav-item": #fff,
      "skin-sidenav-item-hover": $primary-color,
      "skin-gradient-start": rgb(202, 192, 192),
      "skin-gradient-end": #fff,
      "skin-mask-slight": $grey-lighten-2,
      "skin-mask-light": $blue-grey-lighten-5,
      "skin-mask-strong": #333,
      "skin-sn-child": red,
      "skin-btn-primary": #67d590,
      "skin-btn-secondary": #67acd5,
      "skin-btn-default": #fff,
      "skin-text-dark": #333,
      "skin-text-light": #f5f5f5,
      "skin-text": #666,
      "skin-text-success-dark": $primary-color-dark,
      "skin-link": $link,
      "skin-link-hover": $info-color,
      "modal-info": $info-color,
      "skin-btn-padding-y": $btn-padding-y,
      "scrolling-transition": $navbar-scrolling-transition,
    ),
    "NVT": (
      "skin-transparent-color": transparent,
      "skin-primary-color": #78509a,
      "skin-primary-color-dark": #840b55,
      "skin-secondary-color": #863b69,
      "skin-secondary-color-dark": darken(#863b69, 10%),
      "skin-gray-color": #999999,
      "skin-extended-color": #78509a,
      "skin-navbar": #fff,
      "skin-success-color": $success-color,
      "skin-footer-color": #fff,
      "skin-accent": rgba(0, 0, 0, 0.02),
      "skin-flat": $grey-lighten-2,
      "skin-sidenav": #f5f5f5,
      "skin-sidenav-item": #fff,
      "skin-sidenav-item-hover": #512d6d,
      "skin-gradient-start": rgb(202, 192, 192),
      "skin-gradient-end": #fff,
      "skin-mask-slight": $grey-lighten-2,
      "skin-mask-light": $blue-grey-lighten-5,
      "skin-mask-strong": #333,
      "skin-sn-child": red,
      "skin-btn-primary": #78509a,
      "skin-btn-secondary": #512d6d,
      "skin-btn-default": #fff,
      "skin-text-dark": #333,
      "skin-text-light": #f5f5f5,
      "skin-text": #666,
      "skin-text-success-dark": $primary-color-dark,
      "skin-link": $link,
      "skin-link-hover": $info-color,
      "modal-info": $info-color,
      "skin-btn-padding-y": $btn-padding-y,
      "scrolling-transition": $navbar-scrolling-transition,
    ),
    "OLMN": (
      "skin-transparent-color": transparent,
      "skin-primary-color": #899d60,
      "skin-primary-color-dark": darken(#899d60, 10%),
      "skin-secondary-color": #ca8650,
      "skin-secondary-color-dark": darken(#ca8650, 10%),
      "skin-extended-color": #2b597c,
      "skin-navbar": #fff,
      "skin-success-color": $success-color,
      "skin-footer-color": #fff,
      "skin-accent": rgba(0, 0, 0, 0.02),
      "skin-flat": $grey-lighten-2,
      "skin-sidenav": #f5f5f5,
      "skin-sidenav-item": #fff,
      "skin-sidenav-item-hover": #ca8650,
      "skin-gradient-start": rgb(202, 192, 192),
      "skin-gradient-end": #fff,
      "skin-mask-slight": $grey-lighten-2,
      "skin-mask-light": $blue-grey-lighten-5,
      "skin-mask-strong": #333,
      "skin-sn-child": red,
      "skin-btn-secondary": #ca8650,
      "skin-btn-primary": #899d60,
      "skin-btn-default": #fff,
      "skin-text-dark": #333,
      "skin-text-light": #f5f5f5,
      "skin-text": #666,
      "skin-text-success-dark": $primary-color-dark,
      "skin-link": $link,
      "skin-link-hover": $info-color,
      "modal-info": $info-color,
      "skin-btn-padding-y": $btn-padding-y,
      "scrolling-transition": $navbar-scrolling-transition,
    ),
  ),
  $skins
);

@each $skin, $data in $skins {
  .#{$skin}-skin {
    @each $color, $value in $theme-colors {
      .border-#{$color} {
        border-color: $value !important;
      }

      .bg-primary,
      .btn-primary {
        background-color: map-get($data, skin-primary-color) !important;
      }

      .bg-analogous,
      .btn-analogous {
        background-color: map-get($data, skin-analogous-color) !important;
      }

      .bg-accent,
      .btn-accent {
        background-color: map-get($data, skin-accent-color) !important;
      }

      .bg-light {
        background-color: map-get($data, skin-gray-color) !important;
      }

      .text-primary,
      .btn-link {
        color: map-get($data, skin-primary-color) !important;
      }

      .nav-link.active {
        color: map-get($data, skin-primary-color) !important;
        border-color: map-get($data, skin-primary-color) !important;
      }
      mdb-tabs .nav-link.active {
        color: map-get($data, skin-primary-color-dark) !important;
        border-color: map-get($data, skin-primary-color-dark) !important;
      }
    }
    .border {
      &-primary {
        border: map-get($data, skin-primary-color) !important;
      }
      &-secondary {
        border: map-get($data, skin-secondary-color) !important;
      }
      &-gray {
        border: map-get($data, skin-text) !important;
      }
    }
    /* Custom inputs */
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    input[type="number"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
      border-color: map-get($data, skin-primary-color) !important;
      box-shadow: 0 1px 0 0 map-get($data, skin-primary-color);

      & + label {
        color: map-get($data, skin-primary-color) !important;
      }
    }
    input[type="checkbox"] {
      &:focus:not([readonly]) {
        & + label {
          &:before {
            border-right: 2px
              solid
              map-get($data, skin-primary-color) !important;
            border-bottom: 2px
              solid
              map-get($data, skin-primary-color) !important;
          }
          &.form-check-label {
            color: map-get($data, skin-primary-color) !important;
            &:before {
              border: 2px solid map-get($data, skin-primary-color) !important;
            }
          }
        }
      }
      &:checked {
        & + label {
          &:before {
            border-right: 2px
              solid
              map-get($data, skin-primary-color) !important;
            border-bottom: 2px
              solid
              map-get($data, skin-primary-color) !important;
          }
        }
        &:focus {
          & + label {
            &.form-check-label {
              color: map-get($data, skin-primary-color) !important;
              &:before {
                border-top: 2px solid transparent !important;
                border-left: 2px solid transparent !important;
                border-right: 2px
                  solid
                  map-get($data, skin-primary-color) !important;
                border-bottom: 2px
                  solid
                  map-get($data, skin-primary-color) !important;
              }
            }
          }
        }
      }
    }

    .modal-info {
      .modal-header {
        background-color: map-get($data, modal-info) !important;
      }
    }

    /* text color */
    .text {
      &-primary {
        color: map-get($data, skin-primary-color-dark) !important;
      }
      &-secondary {
        color: map-get($data, skin-secondary-color) !important;
      }
      &-gray {
        color: map-get($data, skin-text) !important;
      }
      &-light {
        color: map-get($data, skin-gray-color) !important;
      }
    }
    /* background color */
    .bg-transparent {
      background-color: transparent !important;
    }
    .bg-primary,
    .skin-primary {
      background-color: map-get($data, skin-primary-color) !important;
    }
    .skin-light {
      background-color: map-get($data, skin-mask-slight);
    }
    .skin-text-primary-dark {
      color: map-get($data, skin-primary-color-dark) !important;
    }
    .skin-success-color {
      background-color: map-get($data, skin-success-color);
    }
    .skin-link {
      color: map-get($data, skin-link) !important;
      &:hover {
        color: map-get($data, skin-link-hover) !important;
      }
    }
    .hover {
      &:hover {
        cursor: pointer;
        background-color: map-get($data, skin-mask-light) !important;
      }
    }
  }
}
