.table {
    th {
        padding: 16px;
    }
    
    td {
        vertical-align: middle;
        padding: 14px;
        padding: 8px;
        white-space: nowrap;
    }
}