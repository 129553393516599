.chip {
  // text-transform: capitalize;
  text-align: center;
  white-space: nowrap;
  height: 2.1rem;
  line-height: 2rem;
  // margin: 5px 10px 0 0;
  max-width: fit-content;
  background-color: $white;
  margin: 0;
  border: 1px solid $grey-light;

  &-secondary {
    background-color: $bg-info-lighter;
    border-color: $info-text;
    color: $info-text;
    border-radius: 4px;
    padding: 0 8px;
    pointer-events: none;
  }

  &.active {
      border-color: $primary;
      background-color: $white;
  }

  &-xs {
    height: 16px;
  }

  &-sm {
    height: 28px;
    line-height: 27px;
  }

  &-md {
    height: 4rem;
    line-height: 1.2rem;
  }

  &.chip-rounded {
    width: 32px;
    padding: 0;
    text-align: center;
  }
}
