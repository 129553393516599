$fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts" !default;
$primary-color: #67d590;
$primary: $primary-color;
$form-outline-select-input-focused-color: $primary-color;
$primary-color-dark: #3c9584;
$secondary-color: #67acd5;
$secondary: $secondary-color;
$secondary-color-dark: #4e84a3;
$darker: #333333;
$main: #666666;
$light: #999999;
$lighter: #f7f7f7;

$info-main: #116a9f;
$info-text: #0c4a6f;
$bg-info-lighter: #e7f0f5;

$link: #5a9ec5;
$link-color: $link;
$blue-light: #e9eff7;
$info-color: $link;
$grey-light: #b3b3b3;
$grey-lighten-2: #f5f5f5;
$body-text-color: #666;
$title-color: #333;
$success: #28a745;
$success-color: #28a745;
$warning: #d5c867;
$danger: #d56767;

$grey-lighten-2: #ebebeb;
$blue-grey-lighten-5: #eceff1;

/* badge */
$badge-padding-y: 5px;
$badge-font-size: 85%;
$badge-border-radius: 0.125rem;
$badge-font-weight: 500;

/* table */
$datatable-padding-y: 5px;
$datatable-padding-x: 10px;
$datatable-font-size: 12px;

/* popover */
$popover-box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
  rgba(9, 30, 66, 0.31) 0px 0px 1px;
$popover-max-width: 400px;
/* tooltip */
$tooltip-max-width: 500px;

/* logo */
$sidenav-logo-wrapper-height: 4.625rem;

/* transition */
$navbar-scrolling-transition: background 0.2s ease-in-out,
  padding 0.2s ease-in-out !important;

// button
$btn-border-radius: 2px;
$btn-height: 2.5rem;
$btn-font-size: 0.875rem;
$btn-padding-top: 0.615rem;
$btn-padding-bottom: 0.615rem;
$btn-disabled-opacity: 0.4;
$btn-hover-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2),
  0 4px 10px 0 rgba(0, 0, 0, 0.1);

// button sm
$btn-height-sm: 1.875rem;
$btn-floating-width-sm: $btn-height-sm;
$btn-floating-height-sm: $btn-height-sm;

// button lg
$btn-height-lg: 3.125rem;
$btn-font-size-lg: 1rem;
$btn-padding-top-lg: 0.765rem;
$btn-padding-bottom-lg: $btn-padding-top-lg;

// button floating
$btn-floating-width: $btn-height;
$btn-floating-height: $btn-height;
$btn-floating-width-lg: $btn-height-lg;
$btn-floating-height-lg: $btn-height-lg;

// form
$form-label-margin-bottom: 5px;
$input-height: $btn-height;
$input-height-sm: $btn-height-sm;
$input-font-size: 0.875rem;
$input-font-size-sm: 0.75rem;
$input-padding-x: 0.615rem;
$input-padding-y: 0.75rem;
$label-color: #616161;
$label-font-size: $input-font-size;
$input-height-lg: $btn-height-lg;
$input-disabled-bg: $light;
$input-text-disabled: darken($light, 30%);

// input group
$input-group-height: $input-height;

// input lg
$input-bg: #eceff1;
$input-bg-clear: transparent;

// select
// $input-disabled-background-color: $light;
$select-optoin-font-size: $input-font-size;
// modal
$modal-sm: 400px;
$modal-md: 600px;

/* switch */
$switch-color-bg: $primary-color;
$switch-color-checked-lever-bg: desaturate(
  lighten($primary-color, 25%),
  25%
) !default;
$switch-color-unchecked-lever-bg: $input-bg;

/* z-index */
$zindex-modal-backdrop: 1045;

/* dropdwon */
$dropdown-color: $primary-color;

/* card variable */
$card-cap-bg: rgba(0, 0, 0, 0.02);
$card-border-color: rgba(0, 0, 0, 0.03);

/* modal */
$modal-md: 600px;
$modal-content-border-radius: 0 !important;

/* line height */
$line-height-base: 1.5;
$line-height-sm: 1.25;

/* buttons */
$btn-padding-y: 0.65rem;
$btn-padding-y-basic: $btn-padding-y;
$padding-y: 0.65rem;
$padding-x: 2.14rem;
$font-size: 0.81rem;
$btn-border-radius: 0.125rem;
/* steppers */
$stepper-li-a-circle-bg: #fff;
$stepper-horizontal-li-after-bgc: $stepper-li-a-circle-bg;

/*** Global ***/
/* grid */
$grid-breakpoints: (
  xs: 0,
  sm: 676px,
  md: 992px,
  lg: 1300px,
  xl: 1920px,
);

// spacer
$spacer: 1.25rem;
$spacers: (
  0: 0,
  1: calc($spacer / 4),
  2: calc($spacer / 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2.5,
);

/* Media Query Ranges */
$small-screen-up: 601px;
$medium-screen-up: 993px;
$large-screen-up: 1301px;
$small-screen: 600px;
$medium-screen: 992px;
$large-screen: 1300px;
$sidenav-breakpoint: 900px;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})";
$large-and-up: "only screen and (min-width : #{$medium-screen-up})";
$small-and-down: "only screen and (max-width : #{$small-screen})";
$medium-and-down: "only screen and (max-width : #{$medium-screen})";
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})";
$hide-sidenav: "only screen and (max-width : #{$sidenav-breakpoint})";

$theme-colors: ();
$theme-colors: map-merge(
  (
    "info": $info-color,
    "warning": $warning,
    "danger": $danger,
    "success": $success,
  ),
  $theme-colors
);

/* side nav */
$hover-color: #e0e3e5;
$active-color: #e7eaec;

/* claims processing status */
$queue-color: #6e2d79;
$paid-color: #155724;
$in-review-color: #17a2b8;
$rejected-color: #6f3636;
$denied-color: #888888;
