label {
  font-size: $label-font-size;
  color: $label-color;
}
.col-form-label {
  font-size: $label-font-size;
}
textarea {
  &.form-control {
    min-height: $input-height;
    height: auto;
  }

  &.form-control-sm {
    min-height: $input-height-sm;
  }

  &.form-control-lg {
    min-height: $input-height-lg;
  }
}

// input style
.form-control {
  border: none;
  font-size: $input-font-size;
  padding: $input-padding-x $input-padding-y !important;
  height: $input-height;
  margin-bottom: 0.625rem;
  &.select-input {
    margin-bottom: 0;
  }
  &:disabled {
    color: $input-text-disabled;
    background-color: $light;
    &:hover {
      cursor: not-allowed;
    }
  }
}
.form-control-lg {
  height: $input-height-lg;
}
.form-control-sm {
  height: $input-height-sm;
}
// input clear
.form-clear {
  .form-control {
    border: none;
    background: transparent !important;
  }
  .form-notch {
    .form-notch-leading,
    .form-notch-middle,
    .form-notch-trailing {
      border: 0 !important;
    }
  }
  .select.active,
  .select.focused {
    .form-notch {
      .form-notch-leading,
      .form-notch-middle,
      .form-notch-trailing {
        border: 0 !important;
      }
    }
  }
}

// input group style
.input-group {
  &-lg {
    &:focus {
      transition: $input-group-focus-transition;
      border: 1px solid $input-group-focus-border-color;
      outline: 0;
    }
    & > .form-control {
      margin-bottom: 0;
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }
  }
  & > .form-control {
    margin-bottom: 0;
    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
  &-text {
    border: none;
    background: $input-bg;
  }
}

// select
mdb-select {
  border-radius: 4px;
}
.select-input-clear {
  .select-input {
    color: $white;
    &.form-control {
      &[readonly]:not([disabled]) {
        color: $white;
        background-color: $input-bg-clear;
      }
      &::-webkit-input-placeholder {
        /* Edge */
        color: $white;
      }

      &::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $white;
      }

      &::placeholder {
        color: $white;
      }
    }
  }
}
.select-input {
  &.form-control {
    &[readonly]:not([disabled]) {
      background-color: $white;
    }
  }
  .bg-white > & {
    background-color: $white !important;
  }
  .bg-primary > & {
    color: $white;
    background-color: $primary !important;
    & + .select-arrow {
      color: $white;
    }
  }
  .bg-info > & {
    color: $white;
    background-color: $info !important;
    & + .select-arrow {
      color: $white;
    }
  }
  .bg-success > & {
    color: $white;
    background-color: $success !important;
    & + .select-arrow {
      color: $white;
    }
  }
  .bg-danger > & {
    color: $white;
    background-color: $danger !important;
    & + .select-arrow {
      color: $white;
    }
  }
  .bg-warning > & {
    color: $white;
    background-color: $warning !important;
    & + .select-arrow {
      color: $white;
    }
  }
  .select-sm & {
    height: $input-height-sm;
    font-size: $input-font-size-sm;
    & + .select-arrow {
      top: 5px;
    }
  }
}
.select {
  ~ .form-notch {
    div {
      border: 0;
    }
  }
}
.option {
  font-size: $select-optoin-font-size;
  white-space: normal !important;
  height: auto !important;
  padding: 0.5rem 1rem;
}
// error
.error-select {
  border: 1px solid $danger !important;
}

.is-invalid {
  border: 1px solid $danger !important;
}

.form-outline {
  .form-control {
    background: $white;
    ~ .form-notch {
      div {
        border: 0;
      }
    }
  }
}

.form-check-input:checked {
  border-color: $primary-color-dark !important;
}

.form-check-input[type="radio"]:checked:after {
  border-color: $primary-color-dark !important;
  background-color: $primary-color-dark !important;
}
