
/* For Webkit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 8px; /* Height for horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: $primary-color; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #64c086; /* Thumb color on hover */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 4px; /* Track rounded corners */
}

::-webkit-scrollbar-track:hover {
  background: #ddd; /* Track color on hover */
}