/* Style active/current page */
  .ngx-pagination {
    margin-bottom: 0 !important;
    padding-bottom: 16px !important;

    li.current {
      background-color: $primary-color;
      color: $lighter;
      font-weight: bold;
    }
  }