.modal-content {
  border-radius: 8px;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 1rem 0.625rem !important;
  border: 0 !important;
}

.btn-close {
  font-size: 10px !important;
}

@media (max-width: 676px) {
  .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .modal-dialog {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
  }

  .modal.show .modal-dialog {
    transform: translateY(0);
  }
}

