.dropdown {
    position: relative;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%; 
    left: 0;
    display: block; 
    max-height: 200px; 
    overflow-y: auto; 
    visibility: hidden; 
  }

  .dropdown-item {
    color: $body-text-color;
    cursor: pointer;
  }
  
  .dropdown-menu.show {
    visibility: visible;
  }