mdb-option {
  border-bottom: 1px solid #f6f6f6;
}

.select-dropdown {
  border-radius: 5px;
  margin-left: -20px;
}

.autocomplete-dropdown-container,
.autocomplete-dropdown,
.autocomplete-items-list {
  border-radius: 5px;
}
