html,
body {
  height: 100%;
  background-color: #ecf0f2;
}
body {
  margin: 0;
  font-family: "Varela Round", sans-serif;
  font-weight: 400;
}

p,
small,
button,
input {
  font-family: "Varela", sans-serif;
}

@import "components/form";
@import "components/modal";
@import "components/select";
@import "components/layout";
@import "components/badge";
@import "components/utility";
@import "components/carousel";
@import "components/scrollbar";
@import "components/tabs";
@import "components/pagination";
@import "components/table";
@import "components/card";
@import "components/dropdown";
@import "components/button";
@import "custom-skin";
@import "holista";
