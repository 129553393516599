.chip {
  // text-transform: capitalize;
  text-align: center;
  white-space: nowrap;
  height: 2.1rem;
  line-height: 2rem;
  // margin: 5px 10px 0 0;
  max-width: fit-content;
  background-color: $white;
  margin: 0;
  border-color: $grey-light;

  &.active {
      border-color: $primary;
      background-color: $white;
  }

  &-sm {
    height: 28px;
    line-height: 27px;
  }
  &-md {
    height: 4rem;
    line-height: 1.2rem;
  }
  &.chip-rounded {
    width: 32px;
    padding: 0;
    text-align: center;
  }
}
