/* You can add global styles to this file, and also import other style files */
// owl carousel
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

@import "assets/scss/icofont";
@import "assets/scss/_custom-variables.scss";
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";
@import "assets/scss/custom-styles";
